import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'ngx-strong-frontend-lib/services/breadcrumbs';
import { EButtonStyle } from 'ngx-strong-frontend-lib/components/button';
import { ISelectItem } from 'ngx-strong-frontend-lib/interfaces';
import { LanguageService } from '@app/core/services/language.service';
import { ILanguageGridItem } from '@app/core/interfaces/language';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, finalize, Subject, takeUntil } from 'rxjs';
import { MenuService } from '@app/shared/services/menu.service';
import { IUserMenu } from '@app/core/interfaces/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public mainUrl: string = '/home';
  public breadcrumbs$ = this.breadcrumbsService.observeBreadcrumbs({
    renderNeighbours: 'last',
    accessObjectsKey: 'userAccessObjects',
  });
  readonly EButtonStyle = EButtonStyle;

  public readonly languagesList$ = this.languageService.languagesList$;
  public readonly currentLanguage$ = this.languageService.currentLanguage$;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private menuService: MenuService,
    private breadcrumbsService: BreadcrumbsService,
    private languageService: LanguageService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.languageService.currentLanguage$
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe(() => {
        const menuTranslates = this.translateService.instant('MENU');
        const menu = this.getUserMenu(menuTranslates);
        this.menuService.setUserMenuWithPrepare(menu);
      });
  }

  private getUserMenu(translates: any): IUserMenu[] {
    return [
      {
        code: 'm_User',
        name: translates.USER.TITLE,
        sortOrder: 1,
      },
      {
        code: 'm_System',
        name: translates.SYSTEM_PARAMS.TITLE,
        sortOrder: 2,
      },
      {
        code: 'm_EventLog',
        name: translates.MONITORING.TITLE,
        sortOrder: 3,
      },
      {
        code: 'm_NSI',
        name: translates.NSI.TITLE,
        sortOrder: 4,
      },
    ];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public toggleMenu(open: boolean) {
    this.menuService.setOpenedMenu(open);
  }

  public downloadGuide() {
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    const url = '/assets/documents/И5_Руководство_администратора.docx';
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', 'И5_Руководство_администратора.docx');
    downloadLink.click();
  }

  public changeLanguage(item: ISelectItem<ILanguageGridItem>) {
    this.languageService
      .use(item)
      .pipe(
        finalize(() => {
          // TODO убрать, когда сделаем автоматический перевод заголовков гридов
          window.location.reload();
        }),
      )
      .subscribe();
  }
}
