import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { LocalStorageService } from 'ngx-strong-frontend-lib/services/local-storage';
import { UserApiService } from '@core/services/api/user-api.service';
import { INotifications, IUserInfo } from '@core/interfaces/user';
import { TranslateService } from '@ngx-translate/core';
import { CURRENT_LANGUAGE_KEY } from '@app/app.enums';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userInfoKey: string = 'authUserInfo';
  private _userInfo: IUserInfo;

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private userApiService: UserApiService,
    private translateService: TranslateService,
  ) {
    this._userInfo = this.localStorageService.getObjectByName(this.userInfoKey);
    /**
     * bugfix ASUER-7712
     * Запрос отправляется каждый раз при открытии приложения для "авторизованного" пользователя
     */
    if (
      this.authService.isAuthorized() &&
      !document.location.href.includes('/exit')
    ) {
      this.userApiService.getUserInfo().subscribe((userInfo: IUserInfo) => {
        this.userInfo = userInfo;
        this.checkLanguage();
      });
    }
  }

  /**
   * Проверка (и изменение) языка приложения пользователя после получения информации
   */
  private checkLanguage() {
    const currentLanguage = this.translateService.currentLang;
    const userLanguage = this.userInfo?.language_code?.toLowerCase();
    if (
      !!currentLanguage &&
      !!userLanguage &&
      currentLanguage !== userLanguage
    ) {
      this.localStorageService.setObjectByName(
        CURRENT_LANGUAGE_KEY,
        userLanguage,
      );
      this.translateService.setDefaultLang(userLanguage);
      this.translateService.use(userLanguage);
    }
  }

  get userInfo(): IUserInfo {
    return this._userInfo;
  }

  set userInfo(userInfo: IUserInfo) {
    this._userInfo = userInfo;
    this.localStorageService.setObjectByName(this.userInfoKey, this._userInfo);
  }

  public setUserNotifications(notifications: INotifications) {
    this.userInfo = { ...this._userInfo, ...notifications };
  }

  public logout() {
    this.authService.logout().subscribe(
      (responce) => {
        this.clearUserSession();
      },
      (error) => {
        this.clearUserSession();
      },
    );
  }

  public clearUserSession() {
    this.userInfo = null;
    this.localStorageService.removeAll();
    window.location.href = window.location.origin;
  }
}
