import { EnvironmentInjector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, from, of, switchMap } from 'rxjs';
import { DEFAULT_APP_LANGUAGE_FALLBACK } from '@app/app.enums';

export function TranslateInitializerFactory(
  environmentInjector: EnvironmentInjector,
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = environmentInjector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(),
      );
      const languageService = environmentInjector.get(LanguageService);
      const translateService = environmentInjector.get(TranslateService);
      from(locationInitialized)
        .pipe(
          switchMap(() => languageService.desired()),
          switchMap((lang) => {
            translateService.setDefaultLang(lang);
            return translateService.use(lang);
          }),
          catchError(() => {
            translateService.setDefaultLang(DEFAULT_APP_LANGUAGE_FALLBACK);
            return translateService.use(DEFAULT_APP_LANGUAGE_FALLBACK);
          }),
        )
        .subscribe({
          complete: () => resolve(null),
        });
    });
}
