import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, from, of, switchMap } from 'rxjs';

export type ColorschemeName = 'blue' | 'yellow' | 'red';

/**
 * Сервис динамической конфигурации приложения.
 * Содержит значения по умолчанию или значения,
 * полученные из /env.json
 */
@Injectable()
export class EnvService {
  /**
   * Главная иконка
   */
  public mainIcon: string = '/assets/images/sso-logo-red.png';
  /**
   * Главная иконка маленькая
   */
  public mainMinIcon: string = '/assets/images/sso-logo-min-red.png';
  /**
   * Текст рядом с главной иконкой
   */
  public mainText: string = 'Центральная\nCлужба\nАутентификации';
  /**
   * Favicon
   */
  public favicon: string = 'favicon.ico';
  /**
   * Цветовая схема
   */
  public colorscheme: ColorschemeName = 'red';
  /**
   * Флаг готовности сервиса
   */
  public readonly init: Promise<boolean> = this.initFunc();

  private initFunc(): Promise<boolean> {
    return new Promise((resolve) => {
      from(
        fetch('/env.json', {
          headers: {
            'Cache-Control':
              'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            Pragma: 'no-cache',
            Expires: '0',
          },
        }),
      )
        .pipe(
          switchMap((res) => from(res.json())),
          catchError(() => of({})),
        )
        .subscribe((env) => {
          for (const key in env) {
            if (env.hasOwnProperty(key)) {
              this[key] = env[key];
            }
          }
          resolve(true);
        });
    });
  }
}
