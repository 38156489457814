import { Injectable } from '@angular/core';
import {
  APP_LANGUAGE_AVAILABLE,
  CURRENT_LANGUAGE_KEY,
  DEFAULT_APP_LANGUAGE_FALLBACK,
  LAST_LANGUAGES_LIST_KEY,
} from '@app/app.enums';
import { TranslateService } from '@ngx-translate/core';
import { ISelectItem } from 'ngx-strong-frontend-lib/interfaces';
import { LocalStorageService } from 'ngx-strong-frontend-lib/services/local-storage';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  filter,
  forkJoin,
  from,
  map,
  Observable,
  of,
  retry,
  startWith,
  take,
  throwError,
  timeout,
} from 'rxjs';
import { LanguageApiService } from './api/language-api.service';
import { ILanguageGridItem } from '../interfaces/language';
import { AuthService } from './auth/auth.service';
import { UserApiService } from './api/user-api.service';

/**
 * Сервис для управления локализацией приложения
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly _languagesList$ = new BehaviorSubject<
    ISelectItem<ILanguageGridItem>[]
  >([]);
  /**
   * Лист доступных языков
   */
  public get languagesList$(): Observable<ISelectItem<ILanguageGridItem>[]> {
    return this._languagesList$.asObservable();
  }
  /**
   * Текущий язык приложения
   */
  public get currentLanguage$(): Observable<ISelectItem<ILanguageGridItem>> {
    return combineLatest([
      this.translateService.onLangChange.pipe(
        map((res) => res.lang),
        startWith(this.translateService.currentLang),
      ),
      this._languagesList$,
    ]).pipe(
      map(([currentCode, list]) => {
        if (!currentCode || !list?.length) {
          return null;
        }
        return list.find((item) => item.code.toLowerCase() === currentCode);
      }),
    );
  }

  /**
   * Источники получения кода локализации, по приоритету.
   * Должны возвращать холодные потоки (которые сами завершаются, не эмитят бесконечно).
   */
  private readonly sources: Array<() => Observable<string>> = [
    () => of(this.localStorageService.getObjectByName(CURRENT_LANGUAGE_KEY)),
    () =>
      this._languagesList$.pipe(
        filter((list) => !!list?.length),
        map((list) =>
          list.find((item) => item.ext.is_default)?.code?.toLowerCase(),
        ),
        timeout({ each: 1000, with: () => of(null) }),
        take(1),
      ),
    () => of(DEFAULT_APP_LANGUAGE_FALLBACK),
  ];

  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private languageApiService: LanguageApiService,
    private authService: AuthService,
    private userApiService: UserApiService,
  ) {
    const languagesList: ISelectItem<ILanguageGridItem>[] =
      this.localStorageService.getObjectByName(LAST_LANGUAGES_LIST_KEY);
    if (Array.isArray(languagesList)) {
      this._languagesList$.next(languagesList);
    }

    this.languageApiService
      .list()
      .pipe(
        map((list) =>
          list.filter((item) =>
            APP_LANGUAGE_AVAILABLE.includes(item.ext.code.toLowerCase()),
          ),
        ),
        retry({ delay: 2000 }),
      )
      .subscribe({
        next: (list) => {
          this.localStorageService.setObjectByName(
            LAST_LANGUAGES_LIST_KEY,
            list,
          );
          this._languagesList$.next(list);
        },
      });
  }

  /**
   * Метод получения необходимого языка приложения, в зависимости от различных настроек.
   * @returns
   */
  public desired(): Observable<string> {
    return from(this.sources.map((s) => s())).pipe(
      concatMap((s) => s.pipe(filter((res) => !!res))),
      take(1),
    );
  }

  /**
   * Изменить язык приложения для текущего пользователя.
   * Сохраняет текущий язык в хранилище.
   * Dызывает запрос изменения для авторизованного пользователя.
   * @param language элемент справочника языков
   * @returns
   */
  public use(language: ISelectItem<ILanguageGridItem>): Observable<any> {
    if (!language) {
      return throwError(
        () => new Error(`${this.constructor.name}: language was not provided`),
      );
    }
    const languageId: number = <number>language.key;
    const languageCode: string = language.code.toLowerCase();
    this.localStorageService.setObjectByName(
      CURRENT_LANGUAGE_KEY,
      languageCode,
    );
    const tasks$: Observable<any>[] = [this.translateService.use(languageCode)];
    if (this.authService.isAuthorized()) {
      const req$ = this.userApiService.updateUserLanguage(languageId).pipe(
        catchError((error) => {
          console.error(error);
          return of(error);
        }),
      );
      req$.subscribe();
      tasks$.push(req$);
    }
    return forkJoin(tasks$);
  }
}
