import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapCommonGrid } from '@app/core/functions/grid-mappers';
import { ILanguageGridItem } from '@app/core/interfaces/language';
import { environment } from '@env/environment';
import {
  IGridRequestPayload,
  IPagedResponse,
} from 'ngx-strong-frontend-lib/grid/interfaces';
import { ISelectItem } from 'ngx-strong-frontend-lib/interfaces';
import { map, Observable, share } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageApiService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Грид языков
   * @param requestPayload параметры запроса
   * @returns
   */
  @MapCommonGrid()
  grid(
    requestPayload: IGridRequestPayload,
  ): Observable<IPagedResponse<ILanguageGridItem>> {
    return this.httpClient
      .post<IPagedResponse>(
        `${environment.API_URL}/public/language/grid`,
        requestPayload,
      )
      .pipe(share());
  }

  /**
   * Лист языков
   * @returns
   */
  list(): Observable<ISelectItem<ILanguageGridItem>[]> {
    return this.grid({
      page: { pageNumber: 1, pageSize: 1000 },
      compositeFilters: [],
      search: null,
      sortings: [],
    }).pipe(
      map((res) =>
        res.elements.map((item) => ({
          key: item.id,
          value: item.name,
          code: item.code,
          ext: item,
        })),
      ),
    );
  }

  /**
   * Изменить стандартный язык системы (общий)
   * @param languageId ID языка
   * @returns
   */
  updateSystemDefaultLanguage(languageId: number) {
    return this.httpClient
      .put(`${environment.API_URL}/language/default/${languageId}`, {})
      .pipe(share());
  }
}
