import { applyColorscheme } from 'ngx-strong-frontend-lib/module';
import { EnvService, ColorschemeName } from '../services/env.service';
import {
  colorschemeBlue,
  colorschemeRed,
  colorschemeYellow,
} from 'ngx-strong-frontend-lib/colorscheme';

export const colorschemeInitializer = (envService: EnvService) => {
  return (): Promise<void> => {
    return new Promise((resolve) => {
      envService.init.finally(() => {
        if (envService.colorscheme) {
          applyColorscheme({
            colorscheme: getColorscheme(envService.colorscheme),
          });
        }
        resolve();
      });
    });
  };
};

const getColorscheme = (name: ColorschemeName) => {
  switch (name) {
    case 'blue':
      return colorschemeBlue;
    case 'yellow':
      return colorschemeYellow;
    case 'red':
      return colorschemeRed;
    default:
      return colorschemeBlue;
  }
};
