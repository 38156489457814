import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_APP_LANGUAGE_FALLBACK } from '@app/app.enums';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const lang =
      this.translateService.currentLang ?? DEFAULT_APP_LANGUAGE_FALLBACK;
    req = this.setAcceptLanguage(req, lang);
    return next.handle(req);
  }

  private setAcceptLanguage(
    req: HttpRequest<any>,
    lang: string,
  ): HttpRequest<any> {
    let headers = req.headers ?? new HttpHeaders();
    headers = headers.set('Accept-Language', lang);
    return req.clone({ headers });
  }
}
